<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <div class="card-header" style="padding: 0px">

        <div class="page-header page-header-light" >
          <div class="page-header-content header-elements-lg-inline" >

            <div class="page-title d-flex" style="padding: 0px;">
              <h6>
                <i class="icon-list mr-2"></i> <span class="font-weight-semibold">Stentering Qc </span> - List
              </h6>
              <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
            </div>

            <div class="header-elements d-none">
              <form action="#">
                <div class="form-group">

                  <div class="input-group input-group-feedback input-group-feedback-right wmin-lg-200">
                    <input id="txtsearch" type="text" class="form-control wmin-lg-200" placeholder="Search" autocomplete="off" style="padding-left: 20px;">
                    <div class="form-control-feedback">
                      <i class="icon-search4"></i>
                    </div>

                    <div class="input-group-append position-static">
                      <button type="button" class="btn btn-outline-secondary btn-icon" @click="showFilterWindow">
                        <i class="icon-gear"></i>
                      </button>
                    </div>

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body" style="padding: 0px;">
        <div class="table-responsive">
          <table id="mytable"
                 class="table  table-hover  table-bordered table-condensed table-columned"
                 data-search="false"
                 data-pagination="true"
                 data-show-refresh="false"
                 data-show-columns="false"
                 data-page-list="[10, 25, 50, 100, ALL]"
                 data-show-footer="false"
                 data-toggle="context"
                 data-target=".context-table">
            <thead>
            <tr>
              <th data-field="id" data-class="d-none">id</th>
              <th data-formatter="runningFormatter" data-width="75" >S.No</th>
              <th data-field="doc_date" data-width="110" data-formatter="dateFormatter"  data-sortable="true">Date</th>
              <th data-field="doc_no" data-width="100" data-sortable="true">Doc No</th>
              <th data-field="shift.name" data-sortable="true">Shift</th>
              <th data-field="machine.name" data-sortable="true">Machine</th>
              <th data-field="employee1.name" data-sortable="true">Operator 1</th>
              <th data-field="employee2.name" data-sortable="true">Operator 2</th>
<!--              <th data-field="fab_waste" data-width="110" data-formatter="weightFormat"  data-align="right" data-sortable="true">Fab Waste</th>-->
              <th data-field="remarks" data-sortable="true">Remarks</th>

            </tr>
            </thead>
            <tbody>
            </tbody>
            <tfoot>
            </tfoot>
          </table>
        </div>
      </div>

      <!-- Context Menu -->
      <div id="context-menu" class="context-table">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: blue"></i>Refresh</a>
          <a href="#" class="dropdown-item" ><i class="icon-database-edit2" style="color: #2d7330"></i>Modify</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" ><i class="icon-database-remove" style="color: orangered"></i>Remove</a>
<!--          <div class="dropdown-divider"></div>-->
<!--          <a href="#" class="dropdown-item" ><i class="icon-stamp"></i>Send To QC</a>-->
        </div>
      </div>
      <!-- End of Context Menu -->

      <vmodal name="stenteringqc_modal_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="80%" height="80%" @click="showModal" @before-open="beforeOpen" @before-close="beforeClose" >
        <StenteringQcForm v-bind:myqc="voucher" v-on:stenteringqc_window_closed="closeModal" ></StenteringQcForm>
      </vmodal>


      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showFilterWindow">
            <i class="fab-icon-open icon-filter3"></i>
            <i class="fab-icon-close icon-filter4"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->


      <FocusTrap>
        <vmodal name="filter-window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="575" height="250" @opened="afterfilterwindow_visible">
          <form>
            <div class="card">
              <div class="card-header header-elements-inline bg-primary" style="padding: 10px;">
                <h3 class="card-title">Filter</h3>
                <div class="header-elements">
                  <div class="list-icons">
                    <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
                  </div>
                </div>
              </div>

              <div class="card-body">

                <p/>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">From:</label>
                  <div class="col-md-3">
                    <input id= "txtfrom" ref= "txtfrom" type="date" class="form-control"  v-model="from_date" :max="upto_date" autofocus />
                  </div>

                  <label class="col-md-3 col-form-label">Upto</label>
                  <div class="col-md-3">
                    <input type="date" class="form-control"  v-model="upto_date" :min="from_date" />
                  </div>
                </div>


                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Machine:</label>
                  <div class="col-md-9">
                    <select id="cmbMachine" class="form-control" v-model="machine_id" >
                      <option value="0" selected>All</option>
                      <option v-for="camp in machines" v-bind:value="camp.id">
                        {{ camp.name }}
                      </option>
                    </select>
                  </div>
                </div>


                <div class="text-right">
                  <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Show Data<i class="icon-database-refresh ml-2"></i></button>
                </div>

              </div>
            </div>
          </form>
        </vmodal>
      </FocusTrap>

    </div>

  </FocusTrap>
</template>

<script>
  import StenteringQcForm from '@/views/fms/stentering/StenteringQcForm'
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'

  export default {
    name: 'StenteringQcView',
    store,
    components: {
      StenteringQcForm,
      FabButton,
      moment
    },
    data () {
      return {
        voucher: JSON.parse('{"id":0,"machine":{"id":0,"type":0,"nature":0,"name":"","p_name":""},"shift":{"code":2,"type":0,"name":""},"date":"0001-01-01","emp1":{"id":0,"status":0,"emp_code":"","name":""},"emp2":{"id":0,"status":0,"emp_code":"","name":""},"fabrics":[],"remarks":""}'),
        mytable: {},
        shift: 0,

        from_date: moment().format('YYYY-MM-DD'),
        upto_date: moment().format('YYYY-MM-DD'),
        machine_id : 0,
        machines : [],
        emps: [],
      }
    },
    created () {

    },
    mounted () {
      const self = this;
      this.$data.mytable = $('#mytable');
      this.$data.mytable.bootstrapTable();
      this.loadFilterData();

      $('[data-toggle="popover"]').popover();
      $('#mytable').contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {

          let id = $(row.children('*')[0]).text();

          if ($(e.target).text() === "Refresh") {
            self.loadData();
          }else if($(e.target).text() === 'Modify'){
            self.modifyDocument(id);
          }else if($(e.target).text() == 'Remove'){
            self.removeDocument(id);
          }else if($(e.target).text() == 'Send To QC'){
            self.$modal.show('qc_modal_window');
          }


        }
      });

      $('#txtsearch').keyup(function () {
        let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $('#mytable>tbody>tr').show().filter(function () {
          let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
          return !~text.indexOf(val);
        }).hide();
      });


      self.$data.from_date = moment().add(-7,'d').format('YYYY-MM-DD');
      self.showFilterWindow();
      // self.loadData();

    },
    methods: {
      afterfilterwindow_visible(){
        $('#txtfrom').focus();
      },
      showFilterWindow(){
        this.$modal.show('filter-window');
      },
      closeModal() {
        this.$modal.hide('invoice-window');
        $('#previewmodal').modal('hide');

        // this.$modal.hide('stenteringqc_modal_window');
        $('#stenteringqc_modal_window').modal('hide');


      },
      beforeOpen(){

      },
      beforeClose(){
        this.voucher = JSON.parse('{"id":0,"machine":{"id":0,"type":0,"nature":0,"name":"","p_name":""},"shift":{"code":2,"type":0,"name":""},"date":"0001-01-01","emp1":{"id":0,"status":0,"emp_code":"","name":""},"emp2":{"id":0,"status":0,"emp_code":"","name":""},"fabrics":[],"remarks":""}');
      },
      showModal () {
        this.$modal.show('stenteringqc_modal_window');
      },
      loadFilterData(){

        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };


        self.$data.machines = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/machines/type/4`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            if( !_.isNull(resp.data)){
              self.$data.machines = resp.data;
            }

          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oh noes!" ,  text: err.toString(),  type: "error"});
        });

        self.$data.emps = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/employees`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok){
            if(  !_.isNull(resp.data)){
              self.$data.emps = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oh noes!" ,  text: err.toString(),  type: "error"});
        });
      },
      clear(){
        this.voucher = JSON.parse('{"id":0,"machine":{"id":0,"type":0,"nature":0,"name":"","p_name":""},"shift":{"code":2,"type":0,"name":""},"date":"0001-01-01","emp1":{"id":0,"status":0,"emp_code":"","name":""},"emp2":{"id":0,"status":0,"emp_code":"","name":""},"fabrics":[],"remarks":""}');
      },
      modifyDocument (id) {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        // alert(`${process.env.VUE_APP_ROOT_API}api/stentering_qc/${id}`);

        this.voucher = JSON.parse('{"id":0,"machine":{"id":0,"type":0,"nature":0,"name":"","p_name":""},"shift":{"code":2,"type":0,"name":""},"date":"0001-01-01","emp1":{"id":0,"status":0,"emp_code":"","name":""},"emp2":{"id":0,"status":0,"emp_code":"","name":""},"fabrics":[],"remarks":""}');
        fetch(`${process.env.VUE_APP_ROOT_API}api/stentering_qc/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok ) {
            if(!_.isNull(resp.data)){

              console.log(JSON.stringify(resp.data));

              self.$data.voucher = resp.data;
              self.$data.voucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');

              self.$modal.show('stenteringqc_modal_window');
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text:  err.toString() , type:  "error"} );
        });
      },
      removeDocument (id) {
        const self = this;

        self.$data.voucher.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.voucher)
        };

        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          timer: 3000,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete tms!'
        }).then((result) => {
          if (result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}api/stentering_qc`,requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('stenteringqc_modal_window')
              self.loadData();
              swal(
                'Deleted!',
                'Your request has been processed',
                'success'
              );
            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });

      },
      loadData () {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$modal.hide('filter-window');

        $(self.$data.mytable).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });


        self.$data.mytable.bootstrapTable('load',[]);

        let filters = `from=${self.from_date}&upto=${self.upto_date}&machine_id=${self.machine_id}`;

        // alert(`${process.env.VUE_APP_ROOT_API}api/stentering_qcs?${filters}`);
        fetch(`${process.env.VUE_APP_ROOT_API}api/stentering_qcs?${filters}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {

            // console.log(JSON.stringify(resp.data[0]));

            if(Array.isArray(resp.data)){
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally( ()=> {
          $(self.$data.mytable).unblock();
        });


      }
    }
  }
</script>

<style scoped>

  .modal-content {
    padding: 0px;
  }
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
